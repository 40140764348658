<template>
    <div>
        <v-card flat>
            <!--Lista de turnos regular -->
            <v-list v-if="TurnoMostrar(lista.nombre,0)" class="py-0">
                <!--Entradas Full Laboral -->
                <v-list-item class="text-center px-0 itinerario-via-title rounded-xl"  :color="TemaDark?'grey darken-4':'secondary'">
                    <v-list-item-content class="py-0">
                        <v-card outlined height="30" class="rounded-lg py-1 d-flex align-center justify-center" :color="TemaDark?'grey darken-3':'secondary'">
                            <v-list-item-title class="text-subtitle-2 text-capitalize" :class="TemaDark?'grey--text text--lighten-3':'primary--text'">
                                {{lista.nombre}}
                            </v-list-item-title>
                        </v-card>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-for="(turno) in lista.turnos" :key="turno.id" class="rounded-lg list-planilla px-2" @click="GetTurno(turno.id)">
                    <v-list-item-avatar class="my-0 mr-1 avatar-otros" tile>
                        <v-avatar :color="TemaDark?'grey darken-3':'grey lighten-4'" size="35">
                            <span class="text-body-2 font-weight-medium turno-title text-uppercase" :class="TemaDark?'grey--text text--lighten-1':'grey--text text--darken-1'">
                                {{turno.nombre}}
                            </span>
                        </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content class="py-0">
                        <v-list-item class="pa-0 list-planilla">
                            <v-list-item-content class="py-0 turno_horario">
                                <v-list-item-title class="text-subtitle-2 text-capitalize">
                                    <span class="text-subtitle-2 grey--text">{{turno.hora}} •</span>
                                </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-content class="py-0">
                                <v-card flat v-for="(maquinista, index) in turno.asignaciones" :key="index" color="transparent">
                                    <v-list-item-title class="text-subtitle-2 text-capitalize " :class="maquinista.nombre == 'Sin Maquinista'?'red--text': TemaDark?'grey--text text--lighten-3':'grey--text text--darken-1'">
                                        {{maquinista.nombre}}
                                    </v-list-item-title>
                                    <v-list-item-subtitle class="text-capitalize " :class="TemaDark?'grey--text text--lighten-3':'grey--text text--darken-1'" v-if="maquinista.obs">
                                        <v-icon size="15" style="margin-bottom: 2px !important;">mdi-information-outline</v-icon>
                                        {{maquinista.obs}}
                                    </v-list-item-subtitle>
                                </v-card>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-content>
                    <v-list-item-action class="my-0 avatar-otros pt-2 ml-1" v-if="turno.servicio">
                        <span class="text-subtitle-2 grey--text"><v-icon color="grey" size="15" class="icon-servicio">{{ServicioIcono(turno.servicio_direccion)}}</v-icon>{{turno.servicio}} | {{turno.servicio_inicio}}</span>
                    </v-list-item-action>
                    <v-list-item-action class="my-0 avatar-otros pt-2 ml-1" v-else>
                        <span class="text-subtitle-2 grey--text text-uppercase">{{turno.servicio_direccion}}</span>
                    </v-list-item-action>
                </v-list-item>
            </v-list>
            <!--Lista de Recibidores -->
            <v-list v-if="TurnoMostrar(lista.nombre,1)" :class="col == 1?'hidden-sm-and-up':'d-none d-sm-block'">
                <v-list-item class="text-center px-0 itinerario-via-title rounded-xl"  :color="TemaDark?'grey darken-4':'secondary'">
                    <v-list-item-content class="py-0">
                        <v-card outlined height="30" class="rounded-lg py-1 d-flex align-center justify-center" :color="TemaDark?'grey darken-3':'secondary'">
                            <v-list-item-title class="text-subtitle-2 text-capitalize" :class="TemaDark?'grey--text text--lighten-3':'primary--text'">
                                {{lista.nombre}}
                            </v-list-item-title>
                        </v-card>
                    </v-list-item-content>
                </v-list-item>
                <v-list v-for="(lugar, index) in lista.turnos" :key="index">
                    <v-list-item class="pl-2 pb-2 list-planilla">
                            <v-list-item-content class="py-0">
                                <v-list-item-title class="text-subtitle-2 text-capitalize" :class="TemaDark?'grey--text text--lighten-3':'grey--text text--darken-1'">
                                    <v-icon size="15" class="mr-2 icon-lista">mdi-map-marker-circle</v-icon>{{lugar.lugar}}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    <v-list-item v-for="(turno, index) in lugar.turnos" :key="index" class="rounded-lg list-planilla px-2" @click="GetTurno(turno.id)">
                        <v-list-item-avatar class="my-0 mr-1 avatar-otros">
                            <v-avatar :color="TemaDark?'grey darken-3':'grey lighten-4'" size="35">
                                <span class="text-body-2 font-weight-medium turno-title text-uppercase" :class="TemaDark?'grey--text text--lighten-1':'grey--text text--darken-1'">
                                    {{turno.nombre}}
                                </span>
                            </v-avatar>
                        </v-list-item-avatar>
                        <div style="display: contents;">
                            <v-list-item-content class="py-0">
                                <v-list-item-title class="text-subtitle-2 text-capitalize" :class="maquinista.maquinista == 'Sin Maquinista'?'red--text': TemaDark?'grey--text text--lighten-3':'grey--text text--darken-1'" v-for="(maquinista, index) in turno.maquinista" :key="index">
                                    {{maquinista.maquinista}}
                                    </v-list-item-title>
                                <v-list-item-subtitle v-if="turno.detalle" class="text-capitalize">{{turno.detalle}} ({{turno.detalle_inicio}} - {{turno.detalle_termino}})</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action class="my-0">
                                <span class="text-subtitle-2 grey--text ">{{turno.inicio}} - {{turno.termino}}</span>
                            </v-list-item-action>
                        </div>
                    </v-list-item>
                </v-list>
            </v-list>
            <!--Lista de Otros -->
            <v-list v-if="TurnoMostrar(lista.nombre,2)">
                <v-list-item class="text-center px-0 itinerario-via-title rounded-xl"  :color="TemaDark?'grey darken-4':'secondary'">
                    <v-list-item-content class="py-0">
                        <v-card outlined height="30" class="rounded-lg py-1 d-flex align-center justify-center" :color="TemaDark?'grey darken-3':'secondary'">
                            <v-list-item-title class="text-subtitle-2 text-capitalize" :class="TemaDark?'grey--text text--lighten-3':'primary--text'">
                                {{lista.nombre}}
                            </v-list-item-title>
                        </v-card>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="lista.turnos.reasignar" class="rounded-lg list-planilla px-2">
                    <v-card flat width="100%" color="warning" class="pa-2 rounded-lg">
                        <v-list-item class="px-0" style="min-height:40px">
                            <v-avatar color="warning" size="35" class="mr-2">
                                <v-icon size="30" color="white">mdi-alert-circle-outline</v-icon>
                            </v-avatar>
                            <v-list-item-content class="py-0">
                                <v-list-item-title class="headline mb-0">
                                    <v-toolbar-title class="pa-0 white--text" style="line-height: 1.1;">Reasignar Personal</v-toolbar-title>
                                </v-list-item-title>
                                <v-list-item-subtitle class="font-weight-medium mt-0">
                                    <span class="text-capitalize white--text">{{CantidadReasignar}} Maquinistas</span>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action class="ma-0">
                                <v-btn icon color="white" @click="dialog_reasignar = true">
                                    <v-icon>mdi-arrow-right</v-icon>
                                </v-btn>
                            </v-list-item-action>
                        </v-list-item>               
                    </v-card>
                </v-list-item>
                <v-list-item class="text-center d-flex justify-center px-2" v-if="edit">
                    <v-menu top :offsetY="false" rounded="lg" transition="slide-y-transition">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn depressed text color="primary" v-bind="attrs" v-on="on">
                                <v-icon left size="15" class="mr-1">
                                    mdi-playlist-plus
                                </v-icon>
                                Agregar otros
                            </v-btn>
                        </template>
                        <v-card flat min-height="30vh" max-height="60vh" style="overflow: auto;overflow-x: overlay;">
                            <v-list dense>
                                <v-list-item v-for="turno in lista.turnos.lista" :key="turno.id" @click="GetTurno(turno.id)">
                                    <v-list-item-title class="text-capitalize text-uppercase grey--text text--darken-2">{{ turno.nombre }}</v-list-item-title>
                                    <v-list-item-action class="my-0 text-right" style="display: inline-grid;">
                                        <span class="text-subtitle-2 grey--text text-right text-uppercase">{{turno.turno}}</span>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                        </v-card>
                    </v-menu>
                </v-list-item>
                <v-list class="pb-0" v-for="(turno) in lista.turnos.asignados" :key="turno.id">
                    <v-list-item class="pl-2 pb-0 pb-1 list-planilla">                        
                        <v-list-item-avatar class="my-0 mr-0 avatar-otros" width="200" tile>
                            <v-chip :color="TemaDark?'grey darken-3':'grey lighten-4'">
                                <v-icon left size="20" class="mr-1" :class="TemaDark?'grey--text text--lighten-3':'grey--text text--darken-1'">
                                    mdi-information-outline
                                </v-icon>
                                <v-list-item-title class="text-subtitle-2 text-capitalize" :class="TemaDark?'grey--text text--lighten-1':'grey--text text--darken-1'">{{turno.nombre}}</v-list-item-title>
                            </v-chip>
                        </v-list-item-avatar>
                    </v-list-item>
                    <v-list-item @click="GetTurno(turno.id)" class="rounded-lg list-planilla pr-2 ml-3">
                        <v-list-item-content class="py-1" v-if="turno.detalle.length < 2 || (turno.turno == 'd' || turno.turno == 'd/m' || turno.turno == 'd/t')">
                            <v-list-item class="pa-0 list-planilla" v-for="(maquinista, index) in turno.detalle" :key="index">
                                <v-list-item-content class="py-0">
                                    <v-list-item-title class="text-subtitle-2 text-capitalize" :class="TemaDark?'grey--text text--lighten-1':'grey--text text--darken-1'">• {{maquinista.maquinista}}</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action class="ma-0" style="width:120px;">
                                    <v-row>
                                        <v-col cols="8" class="pa-0 text-right">
                                            <span class="text-subtitle-2 grey--text">
                                                {{DetalleOtros(maquinista)}}
                                            </span>
                                        </v-col>
                                        <v-col cols="4" class="py-0 pr-1 pl-0">
                                            <span class="text-subtitle-2 grey--text">
                                                <v-icon size="15" class="mb-1 mr-0" v-if="DetalleLugar(maquinista)">mdi-map-marker-outline</v-icon>
                                                {{DetalleLugar(maquinista)}}
                                            </span>
                                        </v-col>
                                    </v-row>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list-item-content>
                        <v-list-item-content class="py-1" v-else>
                            <v-list-item class="pa-0 list-planilla">
                                <v-list-item-content class="py-0">
                                    <v-list-item-title class="text-subtitle-2 text-capitalize" :class="TemaDark?'grey--text text--lighten-1':'grey--text text--darken-1'"> • {{turno.detalle.length}} Maquinistas</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action class="ma-0">
                                    <span class="text-subtitle-2 grey--text">
                                        Ver Detalle 
                                        <v-icon class="mb-1" size="18">
                                            mdi-arrow-right
                                        </v-icon>
                                    </span>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-list>
        </v-card>
        <v-dialog v-model="dialog_reasignar" v-if="lista.turnos.reasignar" persistent max-width="450" :transition="DialogAnimation" class="rounded-lg" :overlay-opacity="TemaDark?'.5':'.25'" overlay-color="black">
            <v-card class="rounded-lg">
                <v-card-title class="pa-0 modal-title">
                    <v-toolbar flat class="rounded-t-lg" height="56">
                        <v-toolbar-title class="pa-0 text-capitalize" style="line-height: 1.1;">
                            <v-list-item class="pl-0 rounded-t-lg">
                                <v-list-item-avatar class="mr-2 rounded-lg" :color="TemaDark?'primary':'secondary'" tile width="8" height="30" min-width="8"/>
                                <v-list-item-content class="py-0">
                                    <v-list-item-title class="headline pb-0 mb-0">
                                        <v-toolbar-title class="pa-0 trenos-title-toolbar text-capitalize" :class="TemaDark?'grey--text text--lighten-3':'grey--text text--darken-1'" style="line-height: 1.1;">Reasignar maquinistas</v-toolbar-title>
                                    </v-list-item-title>
                                    <v-list-item-subtitle class="font-weight-medium">
                                        <span class="text-capitalize">{{FechaNombre}}</span>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="dialog_reasignar = false" class="mr-0">
                            <v-icon size="20">mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar>
                </v-card-title>
                <v-card flat max-height="70vh" class="scroll-card pb-2" :disabled="reasignar_card_disable">
                    <v-list v-if="lista.turnos.reasignar" class="rounded-lg list-planilla px-4 py-0">
                        <v-list-item v-for="(maquinista, index) in lista.turnos.reasignar" :key="index" class="rounded-lg list-planilla px-1">
                            <v-list-item-avatar class="my-0 mr-1 avatar-otros" height="48">
                                <v-avatar :color="TemaDark?'grey darken-3':'grey lighten-4'" size="35">
                                    <v-icon size="15" :color="TemaDark?'grey lighten-4 ':'grey'">mdi-account-circle-outline</v-icon>
                                </v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content class="py-0">
                                <v-list-item class="pa-0 list-planilla">
                                    <v-list-item-content class="py-0">
                                        <v-list-item-title class="text-subtitle-2 text-capitalize" :class="TemaDark?'grey--text text--lighten-3':'grey--text text--darken-1'">{{maquinista.nombre}}</v-list-item-title>
                                        <v-list-item-subtitle class="text-capitalize">Turno {{maquinista.turno}}</v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action class="ma-0">
                                        <v-btn text color="primary" depressed @click="LiberarMaquinista(maquinista.turno_id, maquinista.id, fecha)">
                                            Liberar
                                        </v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                    <v-list v-else>
                        <v-row justify="center" align-content="center" class="text-center ma-0">
                            <v-col cols="12">
                                <v-card flat height="120" class="justify-center align-center d-flex flex-column">
                                    <v-icon size="50" color="grey lighten-1">
                                        mdi-alert-circle-outline
                                    </v-icon>
                                    <span class="trenos-title-toolbar grey--text text--lighten-1">Sin informacion</span>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-list>
                </v-card>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import dayjs from "dayjs"
export default {
    name: 'PlanillaLista',
    props: ['lista','col','fds','fecha', 'edit'],
    data() {
        return {
            turno_dialog: false,
            dialog_reasignar: false,
            turno_id: null,
            reasignar_card_disable: false,
            skeleton: true
        }
    },
    computed:{
        ...mapState(['usuario', 'dialog', 'sistema', 'update']),
        //Entrega el estado global de la variable dialog.turno
        TemaDark(){
            let dark = false
            if(this.sistema.tema == 'dark' || (this.sistema.tema == 'system' && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches)) dark = true
            return dark
        },
        CantidadReasignar(){
            let cantidad
            if(this.lista.turnos.reasignar) cantidad = this.lista.turnos.reasignar.length
            return cantidad
        },
        FechaNombre(){
            let fecha_nombre
            if(this.fecha) fecha_nombre = `${dayjs(this.fecha).format('DD')} de ${dayjs(this.fecha).format('MMMM')}`
            return fecha_nombre
        },
        DialogAnimation(){
            let animation = 'fade-transition'
            if(!this.$vuetify.breakpoint.mdAndUp) animation = 'dialog-bottom-transition'
            return animation
        }
    },
    methods:{
        ...mapMutations(['OpenDialog','CloseDialog','ErrorRequest']),
        //Define que tipo de lista mostrar con base en la columna, topo de fecha y nombre de entrada.
        TurnoMostrar(nombre, tipo){
            let mostrar = false
            if(this.fds){ //Fin de semana
                if(!this.col){ //Columna izquierda o 0
                    if(tipo == 0){ // Lista normal
                        if(nombre.includes('Mañana')) mostrar = true
                    }
                    if(tipo == 1){ // Lista recibidores
                        if(nombre == 'Recibidores') mostrar = true
                    }
                }else{ //Columna derecha o 1
                    if(tipo == 0){ // Lista normal
                        if(nombre.includes('Tarde')) mostrar = true
                    }
                    if(tipo == 1){ // Lista recibidores
                        if(nombre == 'Recibidores') mostrar = true
                    }
                    if(tipo == 2){ // Lista otros
                        if(nombre == 'Otros') mostrar = true
                    }
                }
            }else{ //Dias laborales
                if(!this.col){ //Columna izquierda o 0
                    if(tipo == 0){ // Lista normal
                        if(!nombre.includes('Part-Time') && !nombre.includes('Otros') && !nombre.includes('Observaciones') && nombre !== 'Recibidores') mostrar = true
                    }
                    if(tipo == 1){ // Lista recibidores
                        if(nombre == 'Recibidores') mostrar = true
                    }
                }else{ //Columna derecha o 1
                    if(tipo == 0){ // Lista normal
                        if(nombre.includes('Part-Time')) mostrar = true
                    } 
                    if(tipo == 1){ // Lista recibidores
                        if(nombre == 'Recibidores') mostrar = true
                    }
                    if(tipo == 2){ // Lista otros
                        if(nombre.includes('Otros')) mostrar = true
                    }
                }
            }
            return mostrar
        },
        //Abre el componente turno
        GetTurno(turno_id) {
            this.$emit('click_turno',turno_id)
            /*this.turno_id = turno_id
            this.turno_dialog = true
            this.OpenDialog('turno')*/
        },
        //Define el tipo de icono del detalle del servicio con base en la direccion de este.
        ServicioIcono(direccion) {
            let icono
            switch (direccion) {
                case 'pu-li':
                    icono = 'mdi-arrow-up'
                    break;
                case 'li-pu':
                    icono = 'mdi-arrow-down'
                    break;
                case 'pu-sa':
                    icono = 'mdi-arrow-up'
                    break;
                case 'sa-pu':
                    icono = 'mdi-arrow-down'
                    break;
                case 'pu-eb':
                    icono = 'mdi-arrow-up'
                    break;
                case 'eb-pu':
                    icono = 'mdi-arrow-down'
                    break;
                case 'sa-mi':
                    icono = 'mdi-arrow-down'
                    break;
                case 'po-sa':
                    icono = 'mdi-arrow-up'
                    break;
            }
            return icono
        },
        DetalleOtros(maquinista_datos) {
            let detalle = ''
            if (maquinista_datos.inicio !== '0') {
                if (maquinista_datos.termino !== '0') {
                    detalle = `${maquinista_datos.inicio} - ${maquinista_datos.termino} |`
                } else {
                    detalle = `Desde ${maquinista_datos.inicio} |`
                }
            }
            return detalle
        },
        DetalleLugar(maquinista_datos){
            let lugar = null
            if (maquinista_datos.lugar !== '0') {
                switch (maquinista_datos.lugar) {
                    case 'pu':
                        lugar = `PU`
                        break;
                    case 'li':
                        lugar = `LI`
                        break;
                    case 'eb':
                        lugar = `EB`
                        break;
                    case 'sa':
                        lugar = `SA`
                        break;
                    default:
                        break;
                }
            }
            return lugar
        },
        DetalleHorario(nombre, maquinista_lista){
            return maquinista_lista
        },
        //Solicita a la API liberar al maquinista (maquinistas por reasignar)
        async LiberarMaquinista(turno_id, usuario_id, fecha) {
            let datos = {
                turno_id,
                usuario_id,
                fecha,
                code: 0
            }
            this.reasignar_card_disable = true
            await this.axios.post(`/planilla/edit/`, datos, {
                headers: {
                    token: this.usuario.token
                }
            }).then(respuesta => {
                if (respuesta.data.mensaje) {
                    this.ErrorRequest(respuesta.data.mensaje)
                    if (respuesta.data.mensaje.status == 0) {
                        this.update.planilla = true
                    }
                }
                this.reasignar_card_disable = false
            }).catch(err => {
                this.ErrorRequest(err)
                this.reasignar_card_disable = false
            })
        },
    },
    watch: {
        lista: function (val) {
            if (val.length) this.skeleton = false
        }
    },
    created() {
        this.skeleton = true
    }
}
</script>

<style scoped>

.list-planilla {
    min-height: 32px !important;
    max-width: 100%;
}

.v-list-item--link:before {
    border-radius: 8px !important;
}

.icon-servicio {
    vertical-align: text-top !important;
}

.avatar-otros {
    align-self: start !important;
}

.text-body-2{
    line-height: normal !important;
}

.scroll-card{
    will-change: transform !important;
    scroll-behavior: smooth;
    overflow: auto
}

.turno_horario{
    max-width: 48px;
    height: 40px;
    align-self: start;
}

.icon-lista{
    margin-bottom: 2px !important;
}
</style>